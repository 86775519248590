import React, { useEffect, useState } from 'react'
import './estilos.css'
import { scroller, animateScroll as scroll } from "react-scroll";
import { connect } from 'react-redux'
import Menu from './modalMenu'

function NavbarContacto(props) {
    const [shake, setShake] = useState(false);
    const [opacidad, setOpacidad] = useState(1);
    const [menu, setMenu] = useState(1)
    const [modalMenu, setmodalMenu] = useState(false)
    const [cantidad, setcantidad] = useState(0)
    const handleService = (id) => {
        window.location.hash = "#" + id;
    }
    //const [Navbar, setNavbar] = useState(0)
    // useEffect(() => {
    //     window.addEventListener('scroll', () => {

    //     })
    //     //console.log(props)
    // })

    useEffect(async () => {
        document.getElementById('slogan').innerHTML = props.data.Slogan
        window.addEventListener('scroll', () => {
            if ((window.scrollY / window.innerHeight * 2) <= 1) {
                setMenu(1)
                document.getElementById('down').style.display = "block"
                document.getElementById('up').style.display = "none"
            }
            if ((window.scrollY / window.innerHeight * 2) >= 1.3) {
                setMenu(2)
                document.getElementById('down').style.display = "block"
                document.getElementById('up').style.display = "none"
            }
            if ((window.scrollY / window.innerHeight * 2) >= 3) {
                setMenu(3)
                document.getElementById('down').style.display = "none"
                document.getElementById('up').style.display = "block"

            }
        })
        setcantidad(props.productos.reduce((a,c)=>a+c.cantidad,0))

        setShake(true);
        // Buttons stops to shake after 2 seconds
        setTimeout(() => setShake(false), 2000);
    }, [props.productos])

    //props.categorias.length == 1
    const handleMarket = () => {
        setmodalMenu(true)
    }
    return (
        <>
        <div className="NavbarPadre" style={{
                    backgroundColor: `#${props.data.ColorDegradado}`
                }}>
            <div className="NavbarCategoria">
                {props.categorias.map(categoria => {
                    return (
                        <div className="CatHijos" key={categoria.id_cat_mark} onClick={() => handleService(categoria.id_cat_mark)}>
                            <div className="contectDescripcion">{categoria.descripcion}</div>
                        </div>
                    )
                })}
            </div>
            <div style={{ opacity: opacidad, display: `${props.data.Carrito}` }} className="carrito colMenu">
                <div className = {shake ? `marketButonAnimation` : 'marketButon'} style={{ backgroundColor: `#${props.ColorDegradado}` }} onClick={() => handleMarket()}>
                    <label htmlFor="" className = {`productLabel`}>{cantidad}</label>
                    <i className="fas fa-shopping-cart"></i>
                </div>
            </div>
        </div>
        <Menu data={props.productos} estatus={modalMenu} setmodalMenu={setmodalMenu}/>
        </>
    )
}

const PropsStore = state => {
    return {
        data: state.dataPlantilla.data,
        categorias:state.dataPlantilla.categorias,
        productos: state.Productos.productos
    }
}

export default connect(PropsStore, null)(NavbarContacto)